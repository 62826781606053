import { httpService } from './http.service.js'
import { userService } from './user.service.js'
import { utilService } from './util.service.js'

const BASE_URL = 'board/'

export const boardService = {
    query,
    getById,
    getFilteredBoard,
    save,
    remove,
    getDefaultFilterBoard,
    getDefaultFilterBoards,
    getFilterFromSearchParams,
    getEmptyGroup,
    getEmptyTask,
    getEmptyComment,
    getEmptyActivity,
    getEmptyBoard,
    updateTask,
    updateGroup,
    Tasksave,
    projectstatus,
    projectprioritystatus
}

function query(filter = getDefaultFilterBoards()) {
    const queryParams = `?title=${filter.title}&isStarred=${filter.isStarred}`
    return httpService.get(BASE_URL + queryParams)
}

function getFilteredBoard(board, filterBy = getDefaultFilterBoard()) {
    var base = window.location.href.split('/');
    if (base.includes("board")) {
        var user = userService.getLoggedinUser()
        if (!user) {
            window.localStorage.clear()
            window.location.href = "/auth/login"

        }
    }

    const filteredBoard = { ...board }
    if (filterBy.title) {
        const regex = new RegExp(filterBy.title, 'i')
        const groups = filteredBoard.groups.filter(group => regex.test(group.title))
        groups.forEach(group => {
            group.tasks = group.tasks.filter(task => regex.test(task.title))
        })
    }
    if (filterBy.memberId) {
        const groups = filteredBoard.groups
        groups.forEach(group => {
            group.tasks = group.tasks.filter(task => task.memberIds.includes(filterBy.memberId))
        })
    }
    return filteredBoard
}

function getById(boardId) {
    return httpService.get(BASE_URL + boardId)
}

function remove(boardId) {
    return httpService.delete(BASE_URL + boardId)
}

function save(board) {
    console.log('save')
    if (board._id) return httpService.put(BASE_URL + board._id, board)
    return httpService.post(BASE_URL, board)
}

function Tasksave(board_id,group_id,task) {
    console.log('save')
    // if (board_id) return httpService.put(BASE_URL+'addtask' + board_id+'/'+group_id, task)
    return httpService.post(BASE_URL+'addtask/' + board_id+'/'+group_id, task)
}


function updateTask(boardId, groupId, task) {
    console.log('updateTask', { boardId, groupId, task })
    return httpService.put(`${BASE_URL}${boardId}/${groupId}/${task.id}`, task)
}

function updateGroup(boardId, group) {
    console.log('updateGroup')
    return httpService.put(`${BASE_URL}${boardId}/${group.id}`, group)
}

function getDefaultFilterBoards() {
    return {
        title: '',
        isStarred: false
    }
}

function getDefaultFilterBoard() {
    return {
        title: '',
        memberId: ''
    }
}

function getFilterFromSearchParams(searchParams) {
    const emptyFilter = getDefaultFilterBoard()
    const filterBy = {}
    for (const field in emptyFilter) {
        filterBy[field] = searchParams.get(field) || ''
    }
    return filterBy
}

function getEmptyGroup() {
    return {
        "title": 'New Group',
        "archivedAt": Date.now(),
        "tasks": [],
        "color": '#ffcb00',
    }
}

function getEmptyTask() {
    return {
        "title": "",
        "status": "",
        "priority": "",
        "memberIds": [],
        "dueDate": '',
        "comments": [],
        "updatedBy": {
            "imgUrl": "",
        },
        "file": "",
    }
}

function getEmptyComment() {
    return {
        "archivedAt": Date.now(),
        "byMember": {
            "_id": null,
            "fullname": "Guest",
            "imgUrl": "https://res.cloudinary.com/du63kkxhl/image/upload/v1675013009/guest_f8d60j.png"
        }, "txt": "",
        "style": {
            "textDecoration": "none",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "textAlign": "Left"
        }
    }
}

function getEmptyActivity() {
    return {
        "action": "status",
        "createdAt": Date.now(),
        "byMember": userService.getLoggedinUser() || {
            "_id": null,
            "fullname": "Guest",
            "imgUrl": "https://res.cloudinary.com/du63kkxhl/image/upload/v1675013009/guest_f8d60j.png"
        },
        "task": {
            "id": "c101",
            "title": "Replace Logo"
        },
        "from": {},
        "to": {}
    }
}
function projectstatus(){
    return [
        { "id": "l104", "title": "Open", "color": "#9cd326" },
        { "id": "l111", "title": "ReOpen", "color": "#bea925" },
        { "id": "l109", "title": "In-progress", "color": "#ffcb00" },
        { "id": "l102", "title": "Pending", "color": "#e2445c" },
        { "id": "l101", "title": "Completed", "color": "#175a63" },
        { "id": "l105", "title": "Server Updated", "color": "#6cf" },
        { "id": "l103", "title": "Fixed", "color": "#00c875" },
        { "id": "l107", "title": "Need to discuss", "color": "#cab641" },
        { "id": "l106", "title": "Could not recreate", "color": "#a25ddc" },
        { "id": "l108", "title": "Not an issue", "color": "#BB4898" }
    ]
}
function projectprioritystatus(){
    return [
        { "id": "l1019", "title": "High", "color": "#FF0000" },
        { "id": "l1020", "title": "Low", "color": "#008000" },
        { "id": "l1021", "title": "Medium", "color": "#cece5c" },
        { "id": "l1021", "title": "Critical", "color": "#8B0000" },
    ]
}

function getEmptyBoard() {
    var userdetails = userService.getLoggedinUser();
    return {
        "title": 'New Board',
        "archivedAt": Date.now(),
        "isStarred": false,
        "createdBy": userdetails,
        "createdBy_id": userdetails._id,
        "labels": [
            { "id": "l104", "title": "Open", "color": "#9cd326" },
            { "id": "l111", "title": "ReOpen", "color": "#bea925" },
            { "id": "l109", "title": "In-progress", "color": "#ffcb00" },
            { "id": "l102", "title": "Pending", "color": "#e2445c" },
            { "id": "l101", "title": "Completed", "color": "#175a63" },
            { "id": "l105", "title": "Server Updated", "color": "#6cf" },
            { "id": "l103", "title": "Fixed", "color": "#00c875" },
            { "id": "l107", "title": "Need to discuss", "color": "#cab641" },
            { "id": "l106", "title": "Could not recreate", "color": "#a25ddc" },
            { "id": "l108", "title": "Not an issue", "color": "#c4c4c4" },
            { "id": "l1019", "title": "High", "color": "#FF0000" },
            { "id": "l1025", "title": "Blocker", "color": "#be2596" },
            { "id": "l1020", "title": "Low", "color": "#008000" },
            { "id": "l1021", "title": "Medium", "color": "#cece5c" },
        ],
        "members": [userdetails],
        "members_ids": [userdetails._id],
        "groups": [],
        "activities": [],
        "cmpsOrder": ["status-picker", "member-picker", "date-picker", 'priority-picker', 'updated-picker'],
        "description": "",
        "cmpsOption": ["status-picker", "member-picker", "date-picker", 'priority-picker', 'number-picker', 'file-picker', 'updated-picker']
    }
}


