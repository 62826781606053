export const uploadService = {
  uploadImg,
  EditoruploadImg
}
function uploadImg(ev) {
  const CLOUD_NAME = "dcwibf9o5"
  const UPLOAD_PRESET = "vt0iqgff"
  const UPLOAD_URL = `http://18.224.180.157:3030/api/user/uploadfile`
  // const UPLOAD_URL = `https://ehra.shtdevops.xyz:3030/api/user/uploadfile`
  // const UPLOAD_URL = `http://localhost:3030/api/user/uploadfile`
  const formData = new FormData()
  // formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('file', ev.target.files[0])

  return fetch(UPLOAD_URL, {
    method: 'POST',
    body: formData
  })
    .then(res => res.json())
    .then(res => {
      return {
        secure_url: res.hostFilePath,
        height: 400,
        width: 600
      }

    })
    .catch(err => console.error(err))
}

function EditoruploadImg(ev) {
  const CLOUD_NAME = "dcwibf9o5"
  const UPLOAD_PRESET = "vt0iqgff"
  const UPLOAD_URL = `http://18.224.180.157:3030/api/user/uploadfile`
  // const UPLOAD_URL = `https://ehra.shtdevops.xyz:3030/api/user/uploadfile`
  // const UPLOAD_URL = `http://localhost:3030/api/user/uploadfile`
  const formData = new FormData()
  // formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('file', ev)

  return fetch(UPLOAD_URL, {
    method: 'POST',
    body: formData
  })
    .then(res => res.json())
    .then(res => {
      return {
        secure_url: res.hostFilePath,
        height: 400,
        width: 600
      }

    })
    .catch(err => console.error(err))
}