import { Link } from 'react-router-dom'
import { HiOutlineArrowRight } from 'react-icons/hi'
import Logo from './logo' 
import { userService } from '../services/user.service.js'

export function HomeHeader () {
    return (
        <header className="home-header">
               <Logo />
                <div className='header-btns'>
                    <Link to={'/auth/login'}><button className="btn-login">Log in</button></Link>
                    {/* <Link to={`/board/`}><button className='btn-start'>Get started <span className="arrow"><HiOutlineArrowRight /></span></button></Link> */}
                </div>
        </header>
    )
}