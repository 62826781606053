import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { toggleModal, updateTaskAction } from "../../store/board.actions"

import { CgClose } from 'react-icons/cg'
import { GrHomeRounded } from 'react-icons/gr'
import { AiOutlineBold } from 'react-icons/ai'
import { RxUnderline } from 'react-icons/rx'
import { TbAlignRight, TbAlignCenter, TbAlignLeft } from 'react-icons/tb' 
import { boardService } from "../../services/board.service"
import { utilService } from "../../services/util.service"
import { CommentPreview } from "../task/comment-preview"
import { ActivityPreview } from "../activity-preview"
import JoditEditor from 'jodit-react'; 
import { socketService, SOCKET_EMIT_SEND_MSG, SOCKET_EMIT_SET_TOPIC, SOCKET_EVENT_ADD_MSG } from "../../services/socket.service"
import { uploadService } from "../../services/upload.service"
const noUpdate = require('../../assets/img/empty-update.png')

export function TaskModal({ task, board, groupId, setModalCurrTask }) {
    const user = useSelector(storeState => storeState.userModule.user)
    const [comment, setComment] = useState(boardService.getEmptyComment())
    const [isWriteNewUpdate, setIsWriteNewUpdate] = useState(false)
    const [taskActivities, setTaskActivities] = useState([])
    const [isShowUpdate, setIsShowUpdate] = useState(true)
    const [currTask, setCurrTask] = useState(task)
    const navigate = useNavigate()
    const editorRef = useRef(null);
    useEffect(() => {
        loadTaskActivity()
        socketService.emit(SOCKET_EMIT_SET_TOPIC, task.id)
        socketService.on(SOCKET_EVENT_ADD_MSG, addComment)

        return () => {
            socketService.off(SOCKET_EVENT_ADD_MSG, addComment)
        }
    }, []);
   const handlePaste = (e) => {
        let clipboardData = e?.clipboardData || window?.clipboardData;
        let pastedData = clipboardData?.getData('Text');
    console.log(clipboardData); 
        if (clipboardData?.items) {
          let items = clipboardData?.items;
    console.log(items)
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
              let blob = items[i];
              console.log(blob)
              
              // Upload the image
              this.uploadImage(blob);
            }
          }
        }
      }
      const uploadImage = async (imageFile) => {
     
        // console.log(imageFile);
      var c= await uploadImage(imageFile);
      console.log(c);
        // Define your image upload logic here
        // After uploading, get the image URL and insert into the editor
      }
    function addComment(comment) {
        currTask.comments.unshift(comment)
        setCurrTask({ ...currTask })
    }

    function loadTaskActivity() {
        const taskActivities = board.activities.filter(activity => activity.task.id === task.id)
        setTaskActivities(taskActivities)
    }

    function onCloseModal() {
        navigate(`/board/${board._id}`)
        toggleModal(true)
        setModalCurrTask(null)
        setComment(boardService.getEmptyComment())
    }

    async function onUpdateTaskTitle(ev) {
        const value = ev.target.innerText
        task.title = value
        try {
                await updateTaskAction(board, groupId, task)
        } catch (err) {
            console.log('Failed to save')
        }
    }

    async function onAddComment() {
        try {
            comment.id = utilService.makeId()
            if (user) {
                comment.byMember.fullname = user.fullname
                comment.byMember.imgUrl = user.imgUrl
            }
            currTask.comments.unshift(comment)
            socketService.emit(SOCKET_EMIT_SEND_MSG, comment)
            await updateTaskAction(board, groupId, currTask)
            setIsWriteNewUpdate(false)
            setCurrTask({ ...currTask })
            setComment(boardService.getEmptyComment())
        } catch (err) {
            console.log('err:', err)
        }
    }

    function close(ev) {
        ev.preventDefault()
        setIsWriteNewUpdate(false)
        setComment(boardService.getEmptyComment())
    }

    async function onRemoveComment(commentId) {
        try {
            currTask.comments = currTask.comments.filter(comment => comment.id !== commentId)
            updateTaskAction(board, groupId, currTask)
            setCurrTask({ ...currTask })
        } catch (err) {
            console.log('err:', err)
        }
    }

    function onChangeTextStyle(ev, styleKey, align) {
        ev.preventDefault()
        const style = { ...comment.style }
        switch (styleKey) {
            case 'fontStyle':
                style.fontStyle = style.fontStyle === 'normal' ? 'italic' : 'normal'
                break;
            case 'fontWeight':
                style.fontWeight = style[styleKey] === 'normal' ? 'bold' : 'normal'
                break;
            case 'textDecoration':
                style[styleKey] = style[styleKey] === 'none' ? 'underline' : 'none'
                break;
            case 'textAlign':
                style[styleKey] = align
                break;
            default: return
        }
        setComment((prevComment) => ({ ...prevComment, style }))
    }

    function handleChange(target ) {
        // let { value, name: field } = target
       let  value=target;
       let field='txt';
       
        setComment((prevComment) => ({ ...prevComment, [field]: value }))
    }

    async function onEditComment(saveComment) {
        try {
            currTask.comments = currTask.comments.map(comment => (comment.id === saveComment.id) ? saveComment : comment)
            updateTaskAction(board, groupId, task)
            setCurrTask({ ...currTask })
        } catch (err) {
            console.log('err:', err)
        }
    }
 
        const editor = useRef(null);
        const [content, setContent] = useState('');
    
        // const config = useMemo(
        //     {
        //         readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        //         placeholder:'Start typings...',
        //         uploader: {
        //             url: 'http://localhost:5000/posts',
        //      },
        //     },[comment])
        const config = useMemo(() => {
            return {
                language: 'en', 
              readonly: false, // all options from https://xdsoft.net/jodit/doc/options/
              toolbar: true, // Customize toolbar as needed
              height: 200, // Sets the starting height
              minHeight: 100,
              width:600,
              maxWidth:600,
              uploader: {
                insertImageAsBase64URI: false, 
                imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                filesVariableName: function () {
                  return "file";
                },
                withCredentials: false,
                pathVariableName: "file",
                format: "json",
                method: "POST",
                url: "http://localhost:3030/api/user/uploadfie",
                error: function (e) {
                  console.error(e);
                },
                success: function (e) { 
                    console.log(e,'img_path')
                    // if (editor.current) {
                    //     const editors = editor.current.jodit;
                      
                    //     editors.selection.insertHTML(`<img src="${e.data.hostFilePath}" alt="pasted image"/>`);
                    //   }
                  // Handle successful upload here, if needed
                },
              },
              events: {
                paste: async function (event) {
                  const items = (event.clipboardData || event.originalEvent.clipboardData).items;
                  for (let index in items) {
                    const item = items[index];
                    if (item.kind === 'file') {
                      const blob = item.getAsFile();
                      if (blob) {
                       const image_file= await uploadService.EditoruploadImg(blob);
                       console.log(image_file)
                       if(image_file){
                        let img_d=`<a href="${image_file.secure_url}" target="_blank"><img src="${image_file.secure_url}" alt="pasted image" style="width:200px"/></a>`
                        setComment((prevComment) => ({ ...prevComment, 'txt': img_d }))
                       }
                        // const reader = new FileReader();
                        // reader.onload = function (event) {
                        //   // Optionally, you can upload the image here if the built-in functionality doesn't meet your needs
                        // };
                        // reader.readAsDataURL(blob);
                      }
                    }
                  }
                },
              },
              // Other configuration options...
            };
          }, []); 
 
    return <section className='task-modal'>
        <div className="task-modal-header flex column">
            <CgClose className="close-btn" onClick={onCloseModal} />
            <div className="title">
                <blockquote contentEditable onBlur={onUpdateTaskTitle} suppressContentEditableWarning={true}>
                    {task.title}
                </blockquote>
            </div>
        </div>
        <div className="task-modal-type flex">
            <div onClick={() => setIsShowUpdate(!isShowUpdate)} className={`updates-btn ${isShowUpdate ? 'active' : ''}`}>
                <GrHomeRounded />
                <span>Updates</span>
            </div>
            <div onClick={() => setIsShowUpdate(!isShowUpdate)} className={`activity-btn ${!isShowUpdate ? 'active' : ''}`}>
                <span>Activity Log</span>
            </div>
        </div>
        {!isShowUpdate && <ul className="activities">
            {
                taskActivities.map((activity, idx) => {
                    return <li key={idx}><ActivityPreview activity={activity} /></li>
                })
            }
        </ul>}
        {isShowUpdate && <section className="update">
            {!isWriteNewUpdate && <span className="close-input-container flex align-center" onClick={() => setIsWriteNewUpdate(true)}>Write an update</span>}
            {isWriteNewUpdate && <form className="input-container">
            <JoditEditor
			ref={editor}
			value={comment.txt}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => close} // preferred to use only this option to update the content for performance reasons
			onChange={handleChange}
		/>
            {/* <Editor
             onChange={handleChange}
             onEditorChange={(newText) => handleChange}
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={comment.txt}
         onBlur={close}
         
         init={{
           height: 200,
           menubar: false,
           images_upload_handler:()=>{uploadImage(this)},
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        
         }}
         onPaste={()=>{handlePaste()}}
       /> */}
                {/* <div className="style-txt">
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'fontWeight')}><AiOutlineBold /></span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'textDecoration')}><RxUnderline /></span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'fontStyle')}>/</span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'textAlign', 'Left')}><TbAlignLeft /></span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'textAlign', 'Center')}><TbAlignCenter /></span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'textAlign', 'Right')}><TbAlignRight /></span>
                    <span onMouseDown={(ev) => onChangeTextStyle(ev, 'textAlign', 'Right')}><TbAlignRight /></span>
                </div>
                <textarea
                    name="txt"
                    style={comment.style}
                    value={comment.txt}
                    onBlur={close}
                    onChange={handleChange}></textarea> */}
            </form>}
            <br></br>
            <br></br>
           {/* {comment.txt}TOJO */}
            <br></br>
            {isWriteNewUpdate && <div className="button-container"><button className="save" onMouseDown={onAddComment}>Update</button></div>}
            <ul className="comments-list">
                {
                    currTask.comments.map(comment => {
                        return (
                            <li key={comment._id}>
                                <CommentPreview onRemoveComment={onRemoveComment} comment={comment} onEditComment={onEditComment} />
                            </li>
                        )
                    })
                }
            </ul>
            {currTask.comments.length === 0 &&
                <div className="no-updates flex column align-center">
                    <img src={noUpdate} alt="" />
                    <div className="txt flex column align-center">
                        <h2>No updates yet for this item</h2>
                        <p>Be the first one to update about progress, mention someone
                            <br />or upload files to share with your team members
                        </p>
                    </div>
                </div>}
        </section>}
    </section>
}