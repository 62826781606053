import { useSelector } from 'react-redux'

import { closeDynamicModal, setDynamicModalObj, toggleModal } from '../../store/board.actions'
import { RxPencil1 } from 'react-icons/rx'
import { VscTriangleUp } from 'react-icons/vsc'
import { CgClose } from 'react-icons/cg'
import { boardService } from '../../services/board.service'
import { useDispatch } from 'react-redux'

export function ModalStatusProjectPriority({ dynamicModalObj }) {
    const dispatch=useDispatch();
    const board = useSelector(storeState => storeState.boardModule.board)
    const labelpriority= boardService.projectprioritystatus()
 

    function onClickModal(labelTitle) {
        dynamicModalObj.activity.action = dynamicModalObj.type
        dynamicModalObj.activity.to = board.labels.find(label => label.title === labelTitle)
        dynamicModalObj.onTaskUpdate(dynamicModalObj.type, labelTitle, dynamicModalObj.activity)
        dynamicModalObj.isOpen = false
        setDynamicModalObj(dynamicModalObj)
    }
    function closemodal(){

    
        dispatch(closeDynamicModal())
        
        // document.querySelector(".modal-status-priority").remove(); 
    }
 
    return (
        <section className="modal-status-priority ">
             <CgClose className="close-btn" onClick={closemodal} />
            <VscTriangleUp className="triangle-icon" />
            <section className="modal-status-priority-content" >
                
                <ul>
                    {labelpriority.map((label, idx) => <li onClick={() => onClickModal(label.title)} key={idx} style={{ backgroundColor: label.color }}>
                        {label.title}
                    </li>)}
                </ul>
               
            </section>
           
        </section>
    )
}