import Axios from 'axios'
import { userService } from './user.service.js'
// const BASE_URL = 'https://ehra.shtdevops.xyz:3030/api/'
const BASE_URL = 'http://18.224.180.157:3030/api/'
// const BASE_URL = 'http://localhost:3030/api/'


var axios = Axios.create({
    withCredentials: true
})

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    }
}

async function ajax(endpoint, method = 'GET', data = null) {

    try {
        return new Promise(async (resolve, reject) => {
            var userdetails = await userService.getLoggedinUser();
            if (userdetails) {
                axios.defaults.headers.common['userid'] = userdetails._id
            } else {
                axios.defaults.headers.common['userid'] = "";
            }

            axios({
                url: `${BASE_URL}${endpoint}`,
                method,
                data,
                params: (method === 'GET') ? data : null
            }).then(function (response) {

                resolve(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                    resolve(error);
                    // return error
                })
        });

    } catch (err) {
        console.log(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data: `, data)
        console.dir(err)
        if (err.response && err.response.status === 401) {
            sessionStorage.clear()
        }
        if (err.response && err.response.status === 500) {
            window.location.assign('/')
        }
        throw err
    }
}